@charset 'UTF-8';
@use './global' as *;





:root {
  --#{$prefix}root-font-size: #{$root-font-size};

  --#{$prefix}font-size:        #{$font-size};
  --#{$prefix}font-size-medium: .875rem;
  --#{$prefix}font-size-small:  .75rem;
  --#{$prefix}font-size-xsmall: .6875rem;
  --#{$prefix}font-family:      #{$font-family};
  --#{$prefix}font-color:       #{$font-color};

  // Layout
  --#{$prefix}page-bg-color:       #{$page-bg-color};
  --#{$prefix}page-side-padding:   #{$page-side-padding};
  --#{$prefix}container-max-width: #{$container-max-width};

  --#{$prefix}page-header-height:  #{$page-header-height};

  // Spacer
  --#{$prefix}spacer:         #{$spacer};
  --#{$prefix}spacer-small:   #{$spacer-small};
  --#{$prefix}spacer-large:   #{$spacer-large};
  --#{$prefix}section-spacer: #{$section-spacer};
  --#{$prefix}block-spacer:   #{$block-spacer};

  // Shadow
  --#{$prefix}shadow: #{$shadow};
  --#{$prefix}shadow-small: #{$shadow-small};
  --#{$prefix}shadow-large: #{$shadow-large};

  // Radius
  --#{$prefix}radius:       #{$radius};
  --#{$prefix}radius-small: #{$radius-small};
  --#{$prefix}radius-large: #{$radius-large};

  // Transition
  --#{$prefix}transition: #{$transition};

  // Grid
  --#{$prefix}grid-columns: #{$grid-columns};
  --#{$prefix}grid-gap:     #{$grid-gap};

  // Link
  --#{$prefix}link-color:            #{$link-color};
  --#{$prefix}link-hover-color:      #{$link-hover-color};
  --#{$prefix}link-decoration:       #{$link-decoration};
  --#{$prefix}link-hover-decoration: #{$link-hover-decoration};

  // Form
  --#{$prefix}form-font-size:            #{$form-font-size};
  --#{$prefix}placeholder-color:         #{$placeholder-color};
  --#{$prefix}placeholder-font-size:     #{$placeholder-font-size};
  --#{$prefix}focus-ring:                #{$focus-ring};
  --#{$prefix}focus-ring-small:          #{$focus-ring-small};
  --#{$prefix}disabled-background-color: #{$gray-200};
  --#{$prefix}disabled-border-color:     #{$gray-300};
  --#{$prefix}disabled-color:            #{$gray-400};

  // Border
  --#{$prefix}border-color: #{$border-color};
  --#{$prefix}page-border:       #{$page-border};
  --#{$prefix}page-border-thin:  #{$page-border-thin};

  // Theme Colors
  @each $name, $color in $theme-colors {
    --#{$prefix}#{$name}-color: #{$color};
  }

  // Theme Light Colors
  @each $name, $color in $theme-colors {
    --#{$prefix}#{$name}-light-color: #{tint-color($color, $color-tint-amount)};
  }

  // Theme Dark Colors
  @each $name, $color in $theme-colors {
    --#{$prefix}#{$name}-dark-color: #{shade-color($color, $color-shade-amount)};
  }

  // Common Colors
  @each $name, $color in $common-colors {
    --#{$prefix}#{$name}-color: #{$color};
  }

  // Gray Colors
  @each $name, $color in $gray-colors {
    --#{$prefix}#{$name}-color: #{$color};
  }
}





*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: var(--#{$prefix}root-font-size);
  text-size-adjust: 100%;
  height: 100%;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable="false"])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  line-break: after-white-space;
  user-select: auto;
}

:where([draggable="true"]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

body {
  background-color: inherit;
  color: var(--#{$prefix}font-color);
  font-family: var(--#{$prefix}font-family);
  font-size: var(--#{$prefix}font-size);
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
  @media only screen and ( -webkit-min-device-pixel-ratio: 2 ), ( min-resolution: 2dppx ) {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

a {
  color: var(--#{$prefix}link-color);
  cursor: revert;
  text-decoration: var(--#{$prefix}link-decoration);
  @include hover() {
    color: var(--#{$prefix}link-hover-color);
    text-decoration: var(--#{$prefix}link-hover-decoration);
  }
}

hr {
  border-top: 0;
  border-bottom: $hr-border;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: $hr-y-gutter 0;
  width: 100%;
  height: 0;
}

b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}
