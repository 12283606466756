@charset "UTF-8";
:root {
  --basis-root-font-size: 16px;
  --basis-font-size: 1rem;
  --basis-font-size-medium: .875rem;
  --basis-font-size-small: .75rem;
  --basis-font-size-xsmall: .6875rem;
  --basis-font-family: system-ui, -apple-system, Robot, Helvetica Neue, Helvetica, Hiragino Sans, Noto Sans CJK JP, メイリオ, Meiryo, Arial, sans-serif;
  --basis-font-color: #333;
  --basis-page-bg-color: #2d3039;
  --basis-page-side-padding: 1rem;
  --basis-container-max-width: 1000px;
  --basis-page-header-height: 4rem;
  --basis-spacer: 1rem;
  --basis-spacer-small: 0.5rem;
  --basis-spacer-large: 2rem;
  --basis-section-spacer: 4rem;
  --basis-block-spacer: 2rem;
  --basis-shadow: 0.25rem 0 0.625rem #4385bb26;
  --basis-shadow-small: 0 0.125rem 0.25rem #4385bb26;
  --basis-shadow-large: 0 1rem 3rem #4385bb26;
  --basis-radius: 0.5rem;
  --basis-radius-small: 0.25rem;
  --basis-radius-large: 0.875rem;
  --basis-transition: 0.2s ease-out;
  --basis-grid-columns: 12;
  --basis-grid-gap: 1.5rem;
  --basis-link-color: #0033cc;
  --basis-link-hover-color: inherit;
  --basis-link-decoration: none;
  --basis-link-hover-decoration: none;
  --basis-form-font-size: 16px;
  --basis-placeholder-color: #6c757d;
  --basis-placeholder-font-size: 0.75em;
  --basis-focus-ring: 0 0 0 0.25rem #005fcc40;
  --basis-focus-ring-small: 0 0 0 0.125rem #005fcc40;
  --basis-disabled-background-color: #e9ecef;
  --basis-disabled-border-color: #dee2e6;
  --basis-disabled-color: #ced4da;
  --basis-border-color: #ced4da;
  --basis-page-border: 3px solid #25d366;
  --basis-page-border-thin: 2px solid #25d366;
  --basis-primary-color: #2d3039;
  --basis-secondary-color: #454856;
  --basis-tertiary-color: #c8c9d2;
  --basis-quaternary-color: #25d366;
  --basis-accent-color: #0061ff;
  --basis-success-color: #63a764;
  --basis-info-color: #17a2b8;
  --basis-warning-color: #f18f2c;
  --basis-danger-color: #f44336;
  --basis-primary-light-color: rgb(171, 172.2, 175.8);
  --basis-secondary-light-color: rgb(180.6, 181.8, 187.4);
  --basis-tertiary-light-color: rgb(233, 233.4, 237);
  --basis-quaternary-light-color: rgb(167.8, 237.4, 193.8);
  --basis-accent-light-color: rgb(153, 191.8, 255);
  --basis-success-light-color: rgb(192.6, 219.8, 193);
  --basis-info-light-color: rgb(162.2, 217.8, 226.6);
  --basis-warning-light-color: rgb(249.4, 210.2, 170.6);
  --basis-danger-light-color: rgb(250.6, 179.8, 174.6);
  --basis-primary-dark-color: rgb(27, 28.8, 34.2);
  --basis-secondary-dark-color: rgb(41.4, 43.2, 51.6);
  --basis-tertiary-dark-color: rgb(120, 120.6, 126);
  --basis-quaternary-dark-color: rgb(22.2, 126.6, 61.2);
  --basis-accent-dark-color: rgb(0, 58.2, 153);
  --basis-success-dark-color: rgb(59.4, 100.2, 60);
  --basis-info-dark-color: rgb(13.8, 97.2, 110.4);
  --basis-warning-dark-color: rgb(144.6, 85.8, 26.4);
  --basis-danger-dark-color: rgb(146.4, 40.2, 32.4);
  --basis-red-color: #f44336;
  --basis-yellow-color: #fff000;
  --basis-orange-color: #f18f2c;
  --basis-green-color: #63a764;
  --basis-blue-color: #005fcc;
  --basis-pink-color: #ff0066;
  --basis-purple-color: #6f42c1;
  --basis-cyan-color: #17a2b8;
  --basis-gray-100-color: #f8f9fa;
  --basis-gray-200-color: #e9ecef;
  --basis-gray-300-color: #dee2e6;
  --basis-gray-400-color: #ced4da;
  --basis-gray-500-color: #adb5bd;
  --basis-gray-600-color: #6c757d;
  --basis-gray-700-color: #495057;
  --basis-gray-800-color: #343a40;
  --basis-gray-900-color: #212529;
  --basis-gray-dark-color: #333;
  --basis-black-color: #000;
  --basis-white-color: #fff;
}

*:where(:not(html, iframe, canvas, img, svg, video, audio):not(svg *, symbol *)) {
  all: unset;
  display: revert;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-size: var(--basis-root-font-size);
  text-size-adjust: 100%;
  height: 100%;
}

a, button {
  cursor: revert;
}

ol, ul, menu {
  list-style: none;
}

img {
  max-inline-size: 100%;
  max-block-size: 100%;
}

table {
  border-collapse: collapse;
}

input, textarea {
  user-select: auto;
}

textarea {
  white-space: revert;
}

meter {
  appearance: revert;
}

:where(pre) {
  all: revert;
  box-sizing: border-box;
}

::placeholder {
  color: unset;
}

::marker {
  content: initial;
}

:where([hidden]) {
  display: none;
}

:where([contenteditable]:not([contenteditable=false])) {
  -moz-user-modify: read-write;
  -webkit-user-modify: read-write;
  overflow-wrap: break-word;
  line-break: after-white-space;
  user-select: auto;
}

:where([draggable=true]) {
  -webkit-user-drag: element;
}

:where(dialog:modal) {
  all: revert;
  box-sizing: border-box;
}

body {
  background-color: inherit;
  color: var(--basis-font-color);
  font-family: var(--basis-font-family);
  font-size: var(--basis-font-size);
  min-width: 100%;
  height: 100%;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: unset;
}
@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

a {
  color: var(--basis-link-color);
  cursor: revert;
  text-decoration: var(--basis-link-decoration);
}
@media (hover: hover) and (pointer: fine) {
  a:hover {
    color: var(--basis-link-hover-color);
    text-decoration: var(--basis-link-hover-decoration);
  }
}

hr {
  border-top: 0;
  border-bottom: 1px solid #ced4da;
  border-left: 0;
  border-right: 0;
  clear: both;
  margin: 1rem 0;
  width: 100%;
  height: 0;
}

b,
em,
strong {
  font-style: normal;
  font-weight: bold;
  line-height: inherit;
}

.page-container {
  background-color: var(--basis-page-bg-color);
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  min-height: 100%;
}

.page-header {
  background-color: #fff;
  flex: 0 0 auto;
  width: 100%;
  z-index: 1;
}

.page-main {
  background-color: transparent;
  flex: 1 0 auto;
  width: 100%;
  z-index: 0;
}

.page-footer {
  background-color: #fff;
  flex: 0 0 auto;
  width: 100%;
  z-index: 1;
}

article {
  padding: 0 0 4rem;
}

.container {
  margin: 0 auto;
  padding: 0 var(--basis-page-side-padding);
  width: 100%;
  max-width: var(--basis-container-max-width);
}

.section-content,
.section-block {
  width: 100%;
}
.section-content:not(:last-child),
.section-block:not(:last-child) {
  margin-bottom: var(--basis-section-spacer);
}

.block {
  width: 100%;
}
.block:not(:last-child) {
  margin-bottom: var(--basis-block-spacer);
}

.not-found-container {
  display: grid;
  place-items: center;
  padding: 6rem 0;
}
.not-found-container h1 {
  color: var(--basis-white-color);
  font-size: 2rem;
  font-weight: 700;
}

.page-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--basis-spacer) var(--basis-page-side-padding);
  width: 100%;
}

.header-brand-wrapper {
  flex: 1 0 auto;
}
.header-brand-wrapper a {
  display: block;
}
.header-brand-wrapper img {
  width: 12rem;
}

.header-burger-wrapper {
  flex: 0 0 auto;
}

.page-footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  line-height: 0;
  padding: var(--basis-spacer) var(--basis-page-side-padding);
  width: 100%;
}

.footer-copyright {
  color: var(--basis-gray-600-color);
  font-size: var(--basis-font-size-xsmall);
}

.footer-brand a {
  display: block;
}
.footer-brand img {
  height: 1rem;
}

nav.page-navigation {
  background-color: var(--basis-black-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  opacity: 1;
  padding: 6rem 0;
  visibility: visible;
  transition: opacity var(--basis-transition), visibility var(--basis-transition);
  width: 100%;
  height: 100vh;
  z-index: 2;
}
nav.page-navigation[aria-disabled=true] {
  opacity: 0;
  visibility: hidden;
}

.close-navigation {
  background-color: var(--basis-white-color);
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232d3039"%3e%3cpath d="M12,14.1l5.3,5.3c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1L14.1,12l5.3-5.3c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L12,9.9L6.7,4.6C6.1,4,5.2,4,4.6,4.6C4,5.2,4,6.1,4.6,6.7L9.9,12l-5.3,5.3c-0.6,0.6-0.6,1.5,0,2.1c0.6,0.6,1.5,0.6,2.1,0L12,14.1z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  height: var(--basis-page-header-height);
  width: var(--basis-page-header-height);
  transition: background var(--basis-transition);
}
@media (hover: hover) and (pointer: fine) {
  .close-navigation:hover {
    background-color: var(--basis-primary-color);
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M12,14.1l5.3,5.3c0.6,0.6,1.5,0.6,2.1,0c0.6-0.6,0.6-1.5,0-2.1L14.1,12l5.3-5.3c0.6-0.6,0.6-1.5,0-2.1c-0.6-0.6-1.5-0.6-2.1,0L12,9.9L6.7,4.6C6.1,4,5.2,4,4.6,4.6C4,5.2,4,6.1,4.6,6.7L9.9,12l-5.3,5.3c-0.6,0.6-0.6,1.5,0,2.1c0.6,0.6,1.5,0.6,2.1,0L12,14.1z"/%3e%3c/svg%3e');
  }
}

.nav-wrapper {
  display: block;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
  max-width: 768px;
}

.nav-container {
  background-color: var(--basis-primary-color);
  border-radius: var(--basis-radius);
  box-shadow: var(--basis-shadow);
  display: block;
  margin: 0 auto;
  padding: 2rem;
  width: 100%;
}
.nav-container + .nav-container {
  margin-top: var(--basis-block-spacer);
}

.nav-title {
  color: var(--basis-tertiary-color);
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

.categories-nav-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.categories-nav-list li {
  border-bottom: var(--basis-page-border-thin);
}
.categories-nav-list li a {
  background-color: var(--basis-white-color);
  color: var(--basis-font-color);
  display: inline-block;
  font-size: var(--basis-font-size);
  font-weight: 700;
  line-height: 1.6;
  padding: 0.75em 1em;
}

.terms-nav-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 0.5rem;
}

.loading-wrapper {
  background-color: var(--basis-primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity var(--basis-transition), visibility var(--basis-transition);
  z-index: 4;
}
.loading-wrapper[aria-hidden=true] {
  opacity: 0;
  visibility: hidden;
}

.loading-container {
  display: grid;
  place-content: center;
  place-items: center;
}

@keyframes load {
  0% {
    transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px var(--basis-white-color), 0px -40px 0px 0px var(--basis-white-color), 40px 0px 0px 0px var(--basis-white-color), -40px 0px 0px 0px var(--basis-white-color), 40px 40px 0px 0px var(--basis-white-color), -40px -40px 0px 0px var(--basis-white-color), 40px -40px 0px 0px var(--basis-white-color), -40px 40px 0px 0px var(--basis-white-color);
  }
  25% {
    box-shadow: 0px 40px 0px 0px var(--basis-white-color), 0px -40px 0px 0px var(--basis-white-color), 40px 0px 0px 0px var(--basis-white-color), -40px 0px 0px 0px var(--basis-white-color), -40px -40px 0px 0px var(--basis-white-color), 40px 40px 0px 0px var(--basis-white-color), -40px 40px 0px 0px var(--basis-white-color), 40px -40px 0px 0px var(--basis-white-color);
  }
  50% {
    box-shadow: 0px -40px 0px 0px var(--basis-white-color), 0px 40px 0px 0px var(--basis-white-color), -40px 0px 0px 0px var(--basis-white-color), 40px 0px 0px 0px var(--basis-white-color), -40px -40px 0px 0px var(--basis-white-color), 40px 40px 0px 0px var(--basis-white-color), -40px 40px 0px 0px var(--basis-white-color), 40px -40px 0px 0px var(--basis-white-color);
  }
  70% {
    box-shadow: 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color);
  }
  75% {
    box-shadow: 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color);
    height: 10px;
    width: 10px;
  }
  80% {
    background-color: var(--basis-white-color);
    box-shadow: 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color);
    transform: translate(0px, 0px) rotate(360deg);
    height: 40px;
    width: 40px;
  }
  85% {
    background-color: var(--basis-white-color);
    box-shadow: 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color);
    transform: translate(0px, 0px) rotate(360deg);
    height: 40px;
    width: 40px;
  }
  90% {
    background-color: var(--basis-white-color);
    box-shadow: 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color), 0px 0px 0px 0px var(--basis-white-color);
    transform: translate(0px, 0px) rotate(360deg);
    height: 10px;
    width: 10px;
  }
  95% {
    box-shadow: 0px 40px 0px 0px var(--basis-white-color), 0px -40px 0px 0px var(--basis-white-color), 40px 0px 0px 0px var(--basis-white-color), -40px 0px 0px 0px var(--basis-white-color), 40px 40px 0px 0px var(--basis-white-color), -40px -40px 0px 0px var(--basis-white-color), 40px -40px 0px 0px var(--basis-white-color), -40px 40px 0px 0px var(--basis-white-color);
  }
  100% {
    transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px var(--basis-white-color), 0px -40px 0px 0px var(--basis-white-color), 40px 0px 0px 0px var(--basis-white-color), -40px 0px 0px 0px var(--basis-white-color), 40px 40px 0px 0px var(--basis-white-color), -40px -40px 0px 0px var(--basis-white-color), 40px -40px 0px 0px var(--basis-white-color), -40px 40px 0px 0px var(--basis-white-color);
  }
}
.loading-dot {
  animation: load 3s infinite linear;
  background-color: var(--basis-white-color);
  border-radius: 100%;
  box-shadow: 0px 40px 0px 0px var(--basis-white-color), 0px -40px 0px 0px var(--basis-white-color), 40px 0px 0px 0px var(--basis-white-color), -40px 0px 0px 0px var(--basis-white-color), 40px 40px 0px 0px var(--basis-white-color), -40px -40px 0px 0px var(--basis-white-color), 40px -40px 0px 0px var(--basis-white-color), -40px 40px 0px 0px var(--basis-white-color);
  width: 10px;
  height: 10px;
}

.btn-term {
  background-color: var(--basis-white-color);
  border-radius: 2em;
  color: var(--basis-font-color);
  display: inline-flex;
  align-items: center;
  font-size: var(--basis-font-size-small);
  font-weight: 700;
  line-height: 1;
  padding: 0.75em 1.5em 0.75em 1em;
  text-align: center;
}
.btn-term::before {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23333"%3e%3cpath d="M19.8,2C21,2,22,3,22,4.2v5.5c0,0.9-0.3,1.7-1,2.3l-8.5,8.5c-1.3,1.3-3.3,1.3-4.6,0l-4.5-4.5c-1.3-1.3-1.3-3.3,0-4.6L12,3c0.6-0.6,1.4-1,2.3-1H19.8z M19.8,3.5h-5.5c-0.5,0-0.9,0.2-1.2,0.5l-8.5,8.5l0,0c-0.7,0.7-0.7,1.8,0,2.5L9,19.5c0.7,0.7,1.8,0.7,2.5,0l8.5-8.5c0.3-0.3,0.5-0.8,0.5-1.2V4.2C20.5,3.8,20.2,3.5,19.8,3.5z M17,5.5c0.8,0,1.5,0.7,1.5,1.5S17.8,8.5,17,8.5c-0.8,0-1.5-0.7-1.5-1.5S16.2,5.5,17,5.5z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin-right: 4px;
  width: 1.25em;
  height: 1.25em;
}
@media (hover: hover) and (pointer: fine) {
  .btn-term:hover {
    background-color: var(--basis-quaternary-color);
    color: var(--basis-white-color);
  }
  .btn-term:hover::before {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M19.8,2C21,2,22,3,22,4.2v5.5c0,0.9-0.3,1.7-1,2.3l-8.5,8.5c-1.3,1.3-3.3,1.3-4.6,0l-4.5-4.5c-1.3-1.3-1.3-3.3,0-4.6L12,3c0.6-0.6,1.4-1,2.3-1H19.8z M19.8,3.5h-5.5c-0.5,0-0.9,0.2-1.2,0.5l-8.5,8.5l0,0c-0.7,0.7-0.7,1.8,0,2.5L9,19.5c0.7,0.7,1.8,0.7,2.5,0l8.5-8.5c0.3-0.3,0.5-0.8,0.5-1.2V4.2C20.5,3.8,20.2,3.5,19.8,3.5z M17,5.5c0.8,0,1.5,0.7,1.5,1.5S17.8,8.5,17,8.5c-0.8,0-1.5-0.7-1.5-1.5S16.2,5.5,17,5.5z"/%3e%3c/svg%3e');
  }
}

.btn-post-wrapper {
  display: flex;
  justify-content: center;
  margin: 4rem auto 0;
}

.btn-post {
  background-color: var(--basis-accent-color);
  border-radius: var(--basis-radius-small);
  box-shadow: var(--basis-shadow);
  color: var(--basis-white-color);
  display: inline-flex;
  align-items: center;
  font-size: var(--basis-font-size);
  font-weight: 700;
  line-height: 1;
  padding: 1em 1.5em 1em 1.25em;
  text-align: center;
}
.btn-post::before {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M13.9,5l5.1,5.1L9.1,20c-0.3,0.3-0.6,0.5-1,0.6L2.9,22c-0.6,0.2-1.1-0.4-0.9-0.9l1.4-5.1c0.1-0.4,0.3-0.7,0.6-1L13.9,5z M21,3c1.4,1.4,1.4,3.7,0,5.1l-1,1L15,3.9l1-1C17.4,1.6,19.6,1.6,21,3z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin-right: 8px;
  width: 1em;
  height: 1em;
}
@media (hover: hover) and (pointer: fine) {
  .btn-post:hover {
    background-color: var(--basis-accent-dark-color);
    color: var(--basis-white-color);
  }
}

.burger {
  background-color: transparent;
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232d3039"%3e%3cpath d="M3,17h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,19H3c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,17h18H3z M3,11l18,0c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,13L3,13c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,11l18,0L3,11z M3,5h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,7H3C2.4,7,2,6.6,2,6c0-0.5,0.4-0.9,0.9-1L3,5h18H3z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 44px;
  height: 44px;
}
@media (hover: hover) and (pointer: fine) {
  .burger:hover {
    background-color: var(--basis-primary-color);
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M3,17h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,19H3c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,17h18H3z M3,11l18,0c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,13L3,13c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,11l18,0L3,11z M3,5h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,7H3C2.4,7,2,6.6,2,6c0-0.5,0.4-0.9,0.9-1L3,5h18H3z"/%3e%3c/svg%3e');
  }
}
.burger[aria-pressed=true] {
  background-color: var(--basis-accent-color);
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M3,17h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,19H3c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,17h18H3z M3,11l18,0c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,13L3,13c-0.6,0-1-0.4-1-1c0-0.5,0.4-0.9,0.9-1L3,11l18,0L3,11z M3,5h18c0.6,0,1,0.4,1,1c0,0.5-0.4,0.9-0.9,1L21,7H3C2.4,7,2,6.6,2,6c0-0.5,0.4-0.9,0.9-1L3,5h18H3z"/%3e%3c/svg%3e');
}

[class$=-at] {
  display: block;
  font-size: var(--basis-font-size-small);
  font-weight: 500;
  line-height: 1;
}
[class$=-at] p {
  color: var(--basis-gray-600-color);
  font-size: 0.875em;
  margin-bottom: 0.375rem;
}
[class$=-at] span {
  display: inline-flex;
  align-items: center;
}
[class$=-at] span::before {
  content: "";
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  margin-right: 4px;
  width: 1.25em;
  height: 1.25em;
}

.start-at span::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M12,8.2c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v5c0,0.4,0.3,0.8,0.8,0.8h3.5c0.4,0,0.8-0.3,0.8-0.8s-0.3-0.8-0.8-0.8H12V8.2z M3.5,9.6C2.6,8.9,2,7.8,2,6.5c0-2.2,1.8-4,4-4c1.3,0,2.4,0.6,3.1,1.5C10,3.7,11,3.5,12,3.5s2,0.2,2.9,0.5c0.7-0.9,1.9-1.5,3.1-1.5c2.2,0,4,1.8,4,4c0,1.3-0.6,2.4-1.5,3.1c0.3,0.9,0.5,1.9,0.5,2.9c0,2.2-0.8,4.2-2.1,5.8l1.9,1.9c0.3,0.3,0.3,0.8,0,1.1s-0.8,0.3-1.1,0l-1.9-1.9c-1.6,1.3-3.6,2.1-5.8,2.1c-2.2,0-4.2-0.8-5.8-2.1l-1.9,1.9c-0.3,0.3-0.8,0.3-1.1,0s-0.3-0.8,0-1.1l1.9-1.9C3.8,16.7,3,14.7,3,12.5C3,11.5,3.2,10.5,3.5,9.6z M3.5,6.5c0,0.6,0.2,1.2,0.6,1.6c0.8-1.5,2-2.7,3.5-3.5C7.2,4.2,6.6,4,6,4C4.6,4,3.5,5.1,3.5,6.5z M19.9,8.1c0.4-0.4,0.6-1,0.6-1.6C20.5,5.1,19.4,4,18,4c-0.6,0-1.2,0.2-1.6,0.6C17.8,5.4,19.1,6.7,19.9,8.1z M4.5,12.5c0,4.1,3.4,7.5,7.5,7.5c4.1,0,7.5-3.4,7.5-7.5C19.5,8.4,16.1,5,12,5C7.9,5,4.5,8.4,4.5,12.5z"/%3e%3c/svg%3e');
}

.end-at span::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M6.5,12c3,0,5.5,2.5,5.5,5.5S9.5,23,6.5,23S1,20.5,1,17.5S3.5,12,6.5,12z M13,1c5.5,0,10,4.5,10,10s-4.5,10-10,10c-0.3,0-0.7,0-1,0c0.3-0.5,0.6-1,0.7-1.6l0.3,0c4.6,0,8.3-3.7,8.3-8.3S17.6,2.7,13,2.7S4.7,6.4,4.7,11l0,0.3C4.1,11.4,3.5,11.7,3,12c0-0.3,0-0.7,0-1C3,5.5,7.5,1,13,1z M3.7,14.6L3.7,14.6l-0.1,0.1c-0.1,0.2-0.1,0.4,0,0.6l0.1,0.1l2.1,2.1l-2.1,2.1l-0.1,0.1c-0.1,0.2-0.1,0.4,0,0.6l0.1,0.1l0.1,0.1c0.2,0.1,0.4,0.1,0.6,0l0.1-0.1l2.1-2.1l2.1,2.1l0.1,0.1c0.2,0.1,0.4,0.1,0.6,0l0.1-0.1l0.1-0.1c0.1-0.2,0.1-0.4,0-0.6l-0.1-0.1l-2.1-2.1l2.1-2.1l0.1-0.1c0.1-0.2,0.1-0.4,0-0.6l-0.1-0.1l-0.1-0.1c-0.2-0.1-0.4-0.1-0.6,0l-0.1,0.1l-2.1,2.1l-2.1-2.1l-0.1-0.1C4.1,14.5,4,14.5,3.7,14.6L3.7,14.6z M12.2,5c0.4,0,0.7,0.3,0.7,0.6l0,0.1V11h3.2c0.4,0,0.8,0.3,0.8,0.8c0,0.4-0.3,0.7-0.6,0.7l-0.1,0h-4c-0.4,0-0.7-0.3-0.7-0.6l0-0.1v-6C11.5,5.3,11.8,5,12.2,5z"/%3e%3c/svg%3e');
}

.create-at span::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M15.2,13.5h-4c-0.4,0-0.8-0.3-0.8-0.8v-6c0-0.4,0.3-0.8,0.8-0.8S12,6.3,12,6.8V12h3.2c0.4,0,0.8,0.3,0.8,0.8S15.7,13.5,15.2,13.5 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10S17.5,2,12,2"/%3e%3c/svg%3e');
}

.update-at span::before {
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M21,10.1h-6.8L17,7.3c-2.7-2.7-7.1-2.8-9.9-0.1c-2.7,2.7-2.7,7.1,0,9.8s7.2,2.7,9.9,0c1.4-1.3,2-2.9,2-4.9h2c0,2-0.9,4.6-2.6,6.3c-3.5,3.5-9.2,3.5-12.7,0c-3.5-3.5-3.5-9.1,0-12.6s9.1-3.5,12.6,0L21,3V10.1z M12.5,8v4.2l3.5,2.1l-0.7,1.2L11,13V8H12.5z"/%3e%3c/svg%3e');
}

.archives-header {
  padding: 4rem 0;
}
.archives-header h1 {
  color: var(--basis-tertiary-color);
  display: table;
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0 auto;
}

.archives-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--basis-grid-gap);
}

.archives-column {
  background-color: var(--basis-white-color);
  border-radius: var(--basis-radius);
  box-shadow: var(--basis-shadow-small);
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 2;
  row-gap: 0;
  position: relative;
}
.archives-column > a.card-link {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.archives-column .card-heading {
  color: var(--basis-font-color);
  font-size: var(--basis-font-size);
  font-weight: 700;
}
.archives-column .card-heading a {
  color: inherit;
  transition: color var(--basis-transition);
}
@media (hover: hover) and (pointer: fine) {
  .archives-column .card-heading a:hover {
    color: var(--basis-gray-600-color);
  }
}
.archives-column .contents {
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
}
.archives-column .contents:last-child {
  border-radius: 0 0 var(--basis-radius) var(--basis-radius);
  padding-bottom: 1rem;
}
.archives-column .contents.border {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  position: relative;
}
.archives-column .contents.border:not(:first-child)::before {
  content: "";
  background-color: var(--basis-border-color);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
}
.archives-column .contents.sticky {
  background-color: #fffcc;
  backdrop-filter: blur(3px);
  position: sticky;
  bottom: 0;
}

.article-header {
  padding: 4rem 0;
}
.article-header h1 {
  color: var(--basis-tertiary-color);
  display: table;
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0 auto;
}

.article-status {
  color: var(--basis-tertiary-color);
  display: flex;
  justify-content: center;
  margin: 2rem 0 0;
}

.article-terms-list {
  margin: 0 0 1rem;
}
.article-terms-list ul {
  display: flex;
  flex-flow: row wrap;
  gap: 0.5rem 1rem;
}
.article-terms-list ul li > a {
  color: var(--basis-gray-600-color);
  display: flex;
  align-items: center;
  font-size: var(--basis-font-size-medium);
  line-height: 1;
}
.article-terms-list ul li > a::before {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%236c757d"%3e%3cpath d="M19.8,2C21,2,22,3,22,4.2v5.5c0,0.9-0.3,1.7-1,2.3l-8.5,8.5c-1.3,1.3-3.3,1.3-4.6,0l-4.5-4.5c-1.3-1.3-1.3-3.3,0-4.6L12,3c0.6-0.6,1.4-1,2.3-1H19.8z M19.8,3.5h-5.5c-0.5,0-0.9,0.2-1.2,0.5l-8.5,8.5l0,0c-0.7,0.7-0.7,1.8,0,2.5L9,19.5c0.7,0.7,1.8,0.7,2.5,0l8.5-8.5c0.3-0.3,0.5-0.8,0.5-1.2V4.2C20.5,3.8,20.2,3.5,19.8,3.5z M17,5.5c0.8,0,1.5,0.7,1.5,1.5S17.8,8.5,17,8.5c-0.8,0-1.5-0.7-1.5-1.5S16.2,5.5,17,5.5z"/%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  margin-right: 0.125em;
  width: 1em;
  height: 1em;
}
@media (hover: hover) and (pointer: fine) {
  .article-terms-list ul li > a:hover {
    color: var(--basis-white-color);
  }
  .article-terms-list ul li > a:hover::before {
    background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%23fff"%3e%3cpath d="M19.8,2C21,2,22,3,22,4.2v5.5c0,0.9-0.3,1.7-1,2.3l-8.5,8.5c-1.3,1.3-3.3,1.3-4.6,0l-4.5-4.5c-1.3-1.3-1.3-3.3,0-4.6L12,3c0.6-0.6,1.4-1,2.3-1H19.8z M19.8,3.5h-5.5c-0.5,0-0.9,0.2-1.2,0.5l-8.5,8.5l0,0c-0.7,0.7-0.7,1.8,0,2.5L9,19.5c0.7,0.7,1.8,0.7,2.5,0l8.5-8.5c0.3-0.3,0.5-0.8,0.5-1.2V4.2C20.5,3.8,20.2,3.5,19.8,3.5z M17,5.5c0.8,0,1.5,0.7,1.5,1.5S17.8,8.5,17,8.5c-0.8,0-1.5-0.7-1.5-1.5S16.2,5.5,17,5.5z"/%3e%3c/svg%3e');
  }
}

.article-content {
  background-color: var(--basis-white-color);
  border-radius: var(--basis-radius);
  box-shadow: var(--basis-shadow-small);
  padding: 1rem;
  position: relative;
}
@media (min-width: 992px) {
  .article-content {
    padding: 2rem;
  }
}

.pagination-container {
  margin: 4rem 0 0;
  width: auto;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.pagination li {
  display: block;
}
.pagination li:nth-of-type(n+2) {
  margin-left: 0.5rem;
}
.pagination .prev,
.pagination .next,
.pagination .page {
  border: 1px solid transparent;
  color: var(--basis-white-color);
  cursor: pointer;
  display: block;
  font-size: var(--basis-font-size-medium);
  font-weight: 500;
  line-height: 1;
  padding: 0.7em;
  text-align: center;
  transition: background-color var(--basis-transition);
}
@media (hover: hover) and (pointer: fine) {
  .pagination .prev:hover,
  .pagination .next:hover,
  .pagination .page:hover {
    background-color: var(--basis-secondary-color);
    border-color: transparent;
  }
}
.pagination .page[aria-selected=true] {
  background-color: var(--basis-quaternary-color);
  border-color: transparent;
  color: var(--basis-white-color);
  font-weight: 700;
  pointer-events: none;
}
.pagination .between {
  color: var(--basis-white-color);
}

.pagetop {
  background-color: var(--basis-white-color);
  border: 1px solid var(--basis-primary-color);
  border-radius: 50%;
  color: var(--basis-white-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: all var(--basis-transition);
  width: 38px;
  height: 38px;
  z-index: 3;
}
.pagetop:after {
  content: "";
  background-image: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="%232d3039"%3e%3cpath fill-rule="evenodd" d="M11.47 2.47a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 1 1-1.06 1.06l-6.22-6.22V21a.75.75 0 0 1-1.5 0V4.81l-6.22 6.22a.75.75 0 1 1-1.06-1.06l7.5-7.5Z" clip-rule="evenodd" /%3e%3c/svg%3e');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
  width: 1rem;
  height: 1rem;
}
@media (min-width: 992px) {
  .pagetop {
    bottom: 2rem;
    right: 2rem;
    width: 52px;
    height: 52px;
  }
}
.pagetop[aria-disabled=false] {
  opacity: 1;
  visibility: visible;
}