@charset 'UTF-8';
@use './global' as *;



.archives-header {
  padding: 4rem 0;
  h1 {
    color: var(--#{$prefix}tertiary-color);
    display: table;
    font-size: 1.75rem;
    font-weight: 700;
    margin: 0 auto;
  }
}



.archives-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--#{$prefix}grid-gap);
}



.archives-column {
  background-color: var(--#{$prefix}white-color);
  border-radius: var(--#{$prefix}radius);
  box-shadow: var(--#{$prefix}shadow-small);
  display: grid;
  grid-template-rows: subgrid;
  grid-row: span 2;
  row-gap: 0;
  position: relative;

  // カード全体にリンク
  > a.card-link {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }

  // タイトル
  .card-heading {
    color: var(--#{$prefix}font-color);
    font-size: var(--#{$prefix}font-size);
    font-weight: 700;
    a {
      color: inherit;
      transition: color var(--#{$prefix}transition);
      @include hover() {
        color: var(--#{$prefix}gray-600-color);
      }
    }
  }

  .contents {
    padding-top: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    &:last-child {
      border-radius: 0 0 var(--#{$prefix}radius) var(--#{$prefix}radius);
      padding-bottom: 1rem;
    }
    &.border {
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      position: relative;
      &:not(:first-child) {
        &::before {
          content: '';
          background-color: var(--#{$prefix}border-color);
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 1px;
        }
      }
    }
    &.sticky {
      background-color: colorCodeIncludingOpacity($white, 80%);
      backdrop-filter: blur(3px);
      position: sticky;
      bottom: 0;
    }
  }
}
