@charset 'UTF-8';
@use './global' as *;



nav.page-navigation {
  background-color: var(--#{$prefix}black-color);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  opacity: 1;
  padding: 6rem 0;
  visibility: visible;
  transition: opacity var(--#{$prefix}transition), visibility var(--#{$prefix}transition);
  width: 100%;
  height: 100vh;
  z-index: $z-index-navigation;

  &[aria-disabled="true"] {
    opacity: 0;
    visibility: hidden;
  }
}



.close-navigation {
  background-color: var(--#{$prefix}white-color);
  background-image: set-svg($svg-dismiss-bold, $primary);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 70%;
  //border-left: var(--#{$prefix}page-border);
  //border-bottom: var(--#{$prefix}page-border);
  cursor: pointer;
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  height: var(--#{$prefix}page-header-height);
  width: var(--#{$prefix}page-header-height);
  transition: background var(--#{$prefix}transition);
  @include hover(){
    background-color: var(--#{$prefix}primary-color);
    background-image: set-svg($svg-dismiss-bold, $white);
  }
}



.nav-wrapper {
  display: block;
  margin: 0 auto;
  padding: 0 1rem;
  width: 100%;
  max-width: 768px;
}



.nav-container {
  background-color: var(--#{$prefix}primary-color);
  border-radius: var(--#{$prefix}radius);
  box-shadow: var(--#{$prefix}shadow);
  display: block;
  margin: 0 auto;
  padding: 2rem;
  width: 100%;

  & + & {
    margin-top: var(--#{$prefix}block-spacer);
  }
}



.nav-title {
  color: var(--#{$prefix}tertiary-color);
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 1rem;
}



.categories-nav-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  li {
    border-bottom: var(--#{$prefix}page-border-thin);
    a {
      background-color: var(--#{$prefix}white-color);
      color: var(--#{$prefix}font-color);
      display: inline-block;
      font-size: var(--#{$prefix}font-size);
      font-weight: 700;
      line-height: 1.6;
      padding: .75em 1em;
    }
  }
}



.terms-nav-list {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem .5rem;
}
