@charset 'UTF-8';
@use './global' as *;



[class$="-at"] {
  display: block;
  font-size: var(--#{$prefix}font-size-small);
  font-weight: 500;
  line-height: 1;

  p {
    color: var(--#{$prefix}gray-600-color);
    font-size: .875em;
    margin-bottom: .375rem;
  }

  span {
    display: inline-flex;
    align-items: center;
    &::before {
      content: '';
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      display: block;
      margin-right: 4px;
      width: 1.25em;
      height: 1.25em;
    }
  }
}



// 開始日時
.start-at {
  span {
    &::before {
      background-image: set-svg($svg-clock-alarm, $gray-600);
    }
  }
}



// 終了日時
.end-at {
  span {
    &::before {
      background-image: set-svg($svg-clock-dismiss, $gray-600);
    }
  }
}



// 作成日
.create-at {
  span {
    &::before {
      background-image: set-svg($svg-clock, $gray-600);
    }
  }
}



// アップデート
.update-at {
  span {
    &::before {
      background-image: set-svg($svg-update, $gray-600);
    }
  }
}
