@charset 'UTF-8';
@use './color' as *;
@use '../functions' as *;
@use 'sass:math';





// Prefix for :root CSS variables
$prefix: basis- !default;





// root font size
$root-font-size: 16px !default;





// font size percentage
$font-size-percentage: (
  'xsmall'  : 62.5%,
  'small'   : 75%,
  'medium'  : 87.5%,
  'large'   : 112.5%,
  'xlarge'  : 125%,
  'xxlarge' : 150%,
  'exlarge' : 200%
) !default;





// Base font size
$font-size: 1rem !default;

// Base font family
$font-family: system-ui, -apple-system, Robot, 'Helvetica Neue', 'Helvetica', 'Hiragino Sans', 'Noto Sans CJK JP', 'メイリオ', Meiryo, Arial, sans-serif;

// Base font color
$font-color: $gray-dark !default;

// Base line height
$line-height: 1.6 !default;

// Base border color
$border-color: $gray-400 !default;





// Page color
$page-bg-color:      #2d3039 !default; // .page-container
$page-header-bg-color: $white !default;  // .page-header
$page-footer-bg-color: $white !default;  // .page-footer
$page-legal-bg-color:  $gray-100 !default;  // .page-footer-legal

$page-header-height:  4rem !default;

$page-border:      3px solid $quaternary !default;
$page-border-thin: 2px solid $quaternary !default;





// Spacer
$spacer:         1rem !default;
$spacer-small:   $spacer * .5 !default;
$spacer-large:   $spacer * 2 !default;
$section-spacer: $spacer * 4 !default;
$block-spacer:   $spacer * 2 !default;





// Layout
$page-side-padding:   $spacer !default;
$container-max-width: 1000px !default;





// Breakpoints
$breakpoint: 992px !default;





// Grid column
$grid-columns: 12 !default;
$grid-gap:     1.5rem !default;





// Base transition
$transition: .2s ease-out;





// Base radius
$radius:       .5rem !default;
$radius-small: .25rem !default;
$radius-large: .875rem !default;





// Base Shadow
$shadow-color: colorCodeIncludingOpacity(#4385bb, 15%) !default;
$shadow:       .25rem 0 .625rem $shadow-color !default;
$shadow-small: 0 .125rem .25rem $shadow-color !default;
$shadow-large: 0 1rem 3rem $shadow-color !default;





// Heading
$h1-font-size: 2.25rem !default;
$h2-font-size: 2rem !default;
$h3-font-size: 1.75rem !default;
$h4-font-size: 1.5rem !default;
$h5-font-size: 1.25rem !default;
$h6-font-size: 1rem !default;

$heading-margin-bottom: $spacer !default;
$headings-font-weight:  500 !default;
$heading-line-height:   1.2 !default;





// Paragraph.lead
$lead-line-height   : 1.6 !default;
$lead-letter-spacing: 0 !default;





// Anchor links
$link-color:            #0033cc !default;
$link-decoration:       none    !default;
$link-hover-color:      inherit !default;
$link-hover-decoration: none    !default;





// Buttons
$btn-font-size:        1rem !default;
$btn-font-size-small:  $btn-font-size * .8125 !default;
$btn-font-size-large:  $btn-font-size * 1.25 !default;

$btn-font-sizes: (
  'small':  $btn-font-size-small,
  'normal': $btn-font-size,
  'large':  $btn-font-size-large
) !default;

// border 太さ
$btn-border-width: 1px !default;

// 角丸
$btn-border-radius: 4px !default;

// padding 左右
$btn-x-padding:        1em !default;
$btn-x-padding-small:  .875em !default;
$btn-x-padding-large:  1.125em !default;

$btn-x-paddings: (
  'small':  $btn-x-padding-small,
  'normal': $btn-x-padding,
  'large':  $btn-x-padding-large
) !default;

// padding 上下
$btn-y-padding:        .625em !default;
$btn-y-padding-small:  .5em !default;
$btn-y-padding-large:  .625em !default;

$btn-y-paddings: (
  'small':  $btn-y-padding-small,
  'normal': $btn-y-padding,
  'large':  $btn-y-padding-large
) !default;





// Table
$table-border-color:        $border-color !default;
$table-th-background-color: $gray-100 !default;
$table-td-background-color: $white    !default;





// Horizontal line
$hr-border  : 1px solid $border-color !default;
$hr-y-gutter: $spacer !default;





// Labels
$label-font-size:     .75rem !default;
$label-border-radius: 2px !default;
$label-border-width:  1px !default;





// Form
$form-font-size       : 16px !default; // iOSのフォーカス時のズームを無効にするには16px以上を指定
$focus-color          : colorCodeIncludingOpacity($blue, 25%) !default;
$focus-ring           : 0 0 0 .25rem #{$focus-color} !default;
$focus-ring-small     : 0 0 0 .125rem #{$focus-color} !default;
$form-field-gutter    : 2rem !default;
$form-grid-gap     : 8px !default;
$input-x-padding      : 1em !default;
$input-y-padding      : .75em !default;
$placeholder-color    : $gray-600 !default;
$placeholder-font-size: .75em !default;





// z-index
$z-index-header    : 1 !default;
$z-index-navigation: 2 !default;
$z-index-main      : 0 !default;
$z-index-footer    : 1 !default;
$z-index-pagetop   : 3 !default;
$z-index-loading   : 4 !default;





// use colors & functions
$color-shade-amount:   40% !default; // Dark
$color-tint-amount:    60% !default; // Light
$min-contrast-ratio:   4.5 !default;
$color-contrast-dark:  $gray-dark !default;
$color-contrast-light: $white !default;
