@charset 'UTF-8';
@use './global' as *;



.page-container {
  background-color: var(--#{$prefix}page-bg-color);
  display: flex;
  flex-flow: column nowrap;
  align-content: space-between;
  width: 100%;
  min-height: 100%;
}



.page-header {
  background-color: $page-header-bg-color;
  flex: 0 0 auto;
  width: 100%;
  z-index: $z-index-header;
}



.page-main {
  background-color: transparent;
  flex: 1 0 auto;
  width: 100%;
  z-index: $z-index-main;
}



.page-footer {
  background-color: $page-footer-bg-color;
  flex: 0 0 auto;
  width: 100%;
  z-index: $z-index-footer;
}



article {
  padding: 0 0 4rem;
}



.container {
  margin: 0 auto;
  padding: 0 var(--#{$prefix}page-side-padding);
  width: 100%;
  max-width: var(--#{$prefix}container-max-width);
}



.section-content,
.section-block {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: var(--#{$prefix}section-spacer);
  }
}



.block {
  width: 100%;
  &:not(:last-child) {
    margin-bottom: var(--#{$prefix}block-spacer);
  }
}



.not-found-container {
  display: grid;
  place-items: center;
  padding: 6rem 0;
  h1 {
    color: var(--#{$prefix}white-color);
    font-size: 2rem;
    font-weight: 700;
  }
}
