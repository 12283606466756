@charset 'UTF-8';
@use './global' as *;



.page-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: var(--#{$prefix}spacer) var(--#{$prefix}page-side-padding);
  width: 100%;
}



.header-brand-wrapper {
  flex: 1 0 auto;
  a {
    display: block;
  }
  img {
    width: 12rem;
  }
}



.header-burger-wrapper {
  flex: 0 0 auto;
}
