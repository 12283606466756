@charset 'UTF-8';
@use './global' as *;



.page-footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  line-height: 0;
  padding: var(--#{$prefix}spacer) var(--#{$prefix}page-side-padding);
  width: 100%;
}



.footer-copyright {
  color: var(--#{$prefix}gray-600-color);
  font-size: var(--#{$prefix}font-size-xsmall);
}



.footer-brand {
  a {
    display: block;
  }
  img {
    height: 1rem;
  }
}
