@charset 'UTF-8';
@use './global' as *;



.btn-term {
  background-color: var(--#{$prefix}white-color);
  //border: var(--#{$prefix}page-border-thin);
  border-radius: 2em;
  color: var(--#{$prefix}font-color);
  display: inline-flex;
  align-items: center;
  font-size: var(--#{$prefix}font-size-small);
  font-weight: 700;
  line-height: 1;
  padding: .75em 1.5em .75em 1em;
  text-align: center;
  &::before {
    content: '';
    background-image: set-svg($svg-tag, $gray-dark);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: 4px;
    width: 1.25em;
    height: 1.25em;
  }
  @include hover(){
    background-color: var(--#{$prefix}quaternary-color);
    color: var(--#{$prefix}white-color);
    &::before {
      background-image: set-svg($svg-tag, $white);
    }
  }
}



// 投稿するボタン
.btn-post-wrapper {
  display: flex;
  justify-content: center;
  margin: 4rem auto 0;
}

.btn-post {
  background-color: var(--#{$prefix}accent-color);
  border-radius: var(--#{$prefix}radius-small);
  box-shadow: var(--#{$prefix}shadow);
  color: var(--#{$prefix}white-color);
  display: inline-flex;
  align-items: center;
  font-size: var(--#{$prefix}font-size);
  font-weight: 700;
  line-height: 1;
  padding: 1em 1.5em 1em 1.25em;
  text-align: center;
  &::before {
    content: '';
    background-image: set-svg($svg-edit, $white);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: 8px;
    width: 1em;
    height: 1em;
  }
  @include hover(){
    background-color: var(--#{$prefix}accent-dark-color);
    color: var(--#{$prefix}white-color);
  }
}
