@charset 'UTF-8';
@use 'sass:map';
@use '../functions' as *;





// Theme Colors
$primary   : #2d3039 !default;
$secondary : #454856 !default;
$tertiary  : #c8c9d2 !default;
$quaternary: #25d366 !default;
$accent    : #0061ff !default;



// Common Colors
$red   : #f44336 !default;
$yellow: #fff000 !default;
$orange: #f18f2c !default;
$green : #63a764 !default;
$blue  : #005fcc !default;
$pink  : #ff0066 !default;
$purple: #6f42c1 !default;
$cyan  : #17a2b8 !default;



// Gray colors
$gray-100 : #f8f9fa !default;
$gray-200 : #e9ecef !default;
$gray-300 : #dee2e6 !default;
$gray-400 : #ced4da !default;
$gray-500 : #adb5bd !default;
$gray-600 : #6c757d !default;
$gray-700 : #495057 !default;
$gray-800 : #343a40 !default;
$gray-900 : #212529 !default;
$gray-dark: #333    !default;
$black    : #000    !default;
$white    : #fff    !default;

$gray-colors: (
  'gray-100':  $gray-100,
  'gray-200':  $gray-200,
  'gray-300':  $gray-300,
  'gray-400':  $gray-400,
  'gray-500':  $gray-500,
  'gray-600':  $gray-600,
  'gray-700':  $gray-700,
  'gray-800':  $gray-800,
  'gray-900':  $gray-900,
  'gray-dark': $gray-dark,
  'black':     $black,
  'white':     $white
) !default;





// Theme Colors の配列
$theme-colors: (
  'primary'   : $primary,
  'secondary' : $secondary,
  'tertiary'  : $tertiary,
  'quaternary': $quaternary,
  'accent'    : $accent,

  'success'   : $green,
  'info'      : $cyan,
  'warning'   : $orange,
  'danger'    : $red,
) !default;

// Theme Colors CSS変数名（light dark）
$themeColorsKeys: variablesName($theme-colors);



// Common Colors の配列
$common-colors: (
  'red'   : $red,
  'yellow': $yellow,
  'orange': $orange,
  'green' : $green,
  'blue'  : $blue,
  'pink'  : $pink,
  'purple': $purple,
  'cyan'  : $cyan
) !default;



// グレースケールと汎用カラーの配列
$basic-colors: map.merge($gray-colors, $common-colors);



// テーマカラーを含めたすべてのカラーの配列
$all-colors: map.merge($theme-colors, $basic-colors);
