@charset 'UTF-8';
@use './global' as *;



.article-header {
  padding: 4rem 0;
  h1 {
    color: var(--#{$prefix}tertiary-color);
    display: table;
    font-size: 1.75rem;
    font-weight: 700;
    margin: 0 auto;
  }
}



.article-status {
  color: var(--#{$prefix}tertiary-color);
  display: flex;
  justify-content: center;
  margin: 2rem 0 0;
}



.article-terms-list {
  margin: 0 0 1rem;
  ul {
    display: flex;
    flex-flow: row wrap;
    gap: .5rem 1rem;
    li {
      > a {
        color: var(--#{$prefix}gray-600-color);
        display: flex;
        align-items: center;
        font-size: var(--#{$prefix}font-size-medium);
        line-height: 1;
        &::before {
          content: '';
          background-image: set-svg($svg-tag, $gray-600);
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          display: inline-block;
          margin-right: .125em;
          width: 1em;
          height: 1em;
        }
        @include hover(){
          color: var(--#{$prefix}white-color);
          &::before {
            background-image: set-svg($svg-tag, $white);
          }
        }
      }
    }
  }
}



.article-content {
  background-color: var(--#{$prefix}white-color);
  border-radius: var(--#{$prefix}radius);
  box-shadow: var(--#{$prefix}shadow-small);
  padding: 1rem;
  position: relative;
  @include media() {
    padding: 2rem;
  }
}
