@charset 'UTF-8';
@use './global' as *;



.pagination-container {
  margin: 4rem 0 0;
  width: auto;
}



.pagination {
  display: flex;
  justify-content: center;
  align-items: center;

  li {
    display: block;
    &:nth-of-type(n+2){
      margin-left: .5rem;
    }
  }

  .prev,
  .next,
  .page {
    //border-radius: 3px;
    border: 1px solid transparent;
    color: var(--#{$prefix}white-color);
    cursor: pointer;
    display: block;
    font-size: var(--#{$prefix}font-size-medium);
    font-weight: 500;
    line-height: 1;
    padding: .7em;
    text-align: center;
    transition: background-color var(--#{$prefix}transition);
    @include hover() {
      background-color: var(--#{$prefix}secondary-color);
      border-color: transparent;
    }
  }

  .page {
    &[aria-selected="true"] {
      background-color: var(--#{$prefix}quaternary-color);
      border-color: transparent;
      color: var(--#{$prefix}white-color);
      font-weight: 700;
      pointer-events: none;
    }
  }

  .between {
    color: var(--#{$prefix}white-color);
  }
}
