@charset 'UTF-8';
@use './global' as *;



.burger {
  background-color: transparent;
  background-image: set-svg($svg-burger, $primary);
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60%;
  //box-shadow: var(--#{$prefix}shadow);
  //border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  //transition: background-color var(--#{$prefix}transition);
  width: 44px;
  height: 44px;

  @include hover(){
    background-color: var(--#{$prefix}primary-color);
    background-image: set-svg($svg-burger, $white);
  }

  // Active
  &[aria-pressed="true"] {
    background-color: var(--#{$prefix}accent-color);
    background-image: set-svg($svg-burger, $white);
  }
}
