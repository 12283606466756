@charset 'UTF-8';
@use './global' as *;



.pagetop {
  background-color: var(--#{$prefix}white-color);
  border: 1px solid var(--#{$prefix}primary-color);
  border-radius: 50%;
  color: var(--#{$prefix}white-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  opacity: 0;
  visibility: hidden;
  transition: all var(--#{$prefix}transition);
  width: 38px;
  height: 38px;
  z-index: $z-index-pagetop;
  // 矢印
  &:after {
    content: '';
    background-image: set-svg($svg-arrow-up, $primary);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: inline-block;
    width: 1rem;
    height: 1rem;
  }

  @include media() {
    bottom: 2rem;
    right: 2rem;
    width: 52px;
    height: 52px;
  }

  &[aria-disabled="false"] {
    opacity: 1;
    visibility: visible;
  }
}
