@charset 'UTF-8';
@use './global' as *;



.loading-wrapper {
  background-color: var(--#{$prefix}primary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overscroll-behavior: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: opacity var(--#{$prefix}transition), visibility var(--#{$prefix}transition);
  z-index: $z-index-loading;
  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
  }
}



.loading-container {
  display: grid;
  place-content: center;
  place-items: center;
}




$dot: 10;
$dot-size: $dot + px !default;


@keyframes load {
  0% {
    transform: translate(0px, 0px), rotate(0deg);
    box-shadow: 0px 40px 0px 0px var(--#{$prefix}white-color),
                0px -40px 0px 0px var(--#{$prefix}white-color),
                40px 0px 0px 0px var(--#{$prefix}white-color),
                -40px 0px 0px 0px var(--#{$prefix}white-color),
                40px 40px 0px 0px var(--#{$prefix}white-color),
                -40px -40px 0px 0px var(--#{$prefix}white-color),
                40px -40px 0px 0px var(--#{$prefix}white-color),
                -40px 40px 0px 0px var(--#{$prefix}white-color);
  }

  25% {
    box-shadow: 0px 40px 0px 0px var(--#{$prefix}white-color),
                0px -40px 0px 0px var(--#{$prefix}white-color),
                40px 0px 0px 0px var(--#{$prefix}white-color),
                -40px 0px 0px 0px var(--#{$prefix}white-color),
                -40px -40px 0px 0px var(--#{$prefix}white-color),
                40px 40px 0px 0px var(--#{$prefix}white-color),
                -40px 40px 0px 0px var(--#{$prefix}white-color),
                40px -40px 0px 0px var(--#{$prefix}white-color);
  }

  50% {
    box-shadow: 0px -40px 0px 0px var(--#{$prefix}white-color),
                0px 40px 0px 0px var(--#{$prefix}white-color),
                -40px 0px 0px 0px var(--#{$prefix}white-color),
                40px 0px 0px 0px var(--#{$prefix}white-color),
                -40px -40px 0px 0px var(--#{$prefix}white-color),
                40px 40px 0px 0px var(--#{$prefix}white-color),
                -40px 40px 0px 0px var(--#{$prefix}white-color),
                40px -40px 0px 0px var(--#{$prefix}white-color);
  }

  70% {
    box-shadow: 0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color);
  }

  75% {
    box-shadow: 0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color);
    height: $dot-size;
    width: $dot-size;
  }

  80% {
    background-color: var(--#{$prefix}white-color);

    box-shadow: 0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color);
    transform: translate(0px, 0px) rotate(360deg);
    height: 40px;
    width: 40px;
  }

  85% {
    background-color: var(--#{$prefix}white-color);
    box-shadow: 0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color);
    transform: translate(0px, 0px) rotate(360deg);
    height: 40px;
    width: 40px;
  }

  90% {
    background-color: var(--#{$prefix}white-color);
    box-shadow: 0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color),
                0px 0px 0px 0px var(--#{$prefix}white-color);
    transform: translate(0px, 0px) rotate(360deg);
    height: $dot-size;
    width: $dot-size;
  }

  95% {
    box-shadow: 0px 40px 0px 0px var(--#{$prefix}white-color),
                0px -40px 0px 0px var(--#{$prefix}white-color),
                40px 0px 0px 0px var(--#{$prefix}white-color),
                -40px 0px 0px 0px var(--#{$prefix}white-color),
                40px 40px 0px 0px var(--#{$prefix}white-color),
                -40px -40px 0px 0px var(--#{$prefix}white-color),
                40px -40px 0px 0px var(--#{$prefix}white-color),
                -40px 40px 0px 0px var(--#{$prefix}white-color);
  }

  100% {
    transform: rotate(360deg);
    box-shadow: 0px 40px 0px 0px var(--#{$prefix}white-color),
                0px -40px 0px 0px var(--#{$prefix}white-color),
                40px 0px 0px 0px var(--#{$prefix}white-color),
                -40px 0px 0px 0px var(--#{$prefix}white-color),
                40px 40px 0px 0px var(--#{$prefix}white-color),
                -40px -40px 0px 0px var(--#{$prefix}white-color),
                40px -40px 0px 0px var(--#{$prefix}white-color),
                -40px 40px 0px 0px var(--#{$prefix}white-color);
  }

}





.loading-dot {
  animation: load 3s infinite linear;
  background-color: var(--#{$prefix}white-color);
  border-radius: 100%;
  box-shadow: 0px 40px 0px 0px var(--#{$prefix}white-color),
              0px -40px 0px 0px var(--#{$prefix}white-color),
              40px 0px 0px 0px var(--#{$prefix}white-color),
              -40px 0px 0px 0px var(--#{$prefix}white-color),
              40px 40px 0px 0px var(--#{$prefix}white-color),
              -40px -40px 0px 0px var(--#{$prefix}white-color),
              40px -40px 0px 0px var(--#{$prefix}white-color),
              -40px 40px 0px 0px var(--#{$prefix}white-color);
  width: $dot-size;
  height: $dot-size;
}
